import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ReactPlayer from 'react-player'
import windowSize from 'react-window-size';
import {isMobile} from 'react-device-detect';

var width = 0
var height = 0;

const Support = () =>
  <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div  style={{backgroundImage:'radial-gradient(rgba(0,150,0,1), rgba(0,150,0,.4))', textAlign:'center', marginLeft: '10px', marginRight: '10px', fontWeight: '800', color:'white', width: (isMobile ? width : '50vw'), fontSize: (isMobile ? "20px" : "20px")}}>
          Need support? Contact marc@letheapp.com below!
      </div>
      <br/>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <a href="/" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Home</a>
        <a href="mailto:marc@letheapp.com" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Contact</a>
        <a href="/terms" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Terms</a>
      </div>
    </div>
  </div>;

const Landing = () => (
  <div style={{height: '100vh', minHeight: '100vh', minWidth: "100vw"}}>
    <div style={{display: 'flex', justifyContent:'center', alignItems:'center', height: '100vh', minHeight: '100vh', width:'100wh'}}>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <ReactPlayer width={width < 500 ? width : 700} height={(width-(width*.50)) < 300 ? (width-(width*.25)) : 400} url='https://youtu.be/y07cvmZLTOI' />
        <br/>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
          <a href="/terms" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Terms</a>
          <a href="mailto:marc@letheapp.com" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Contact</a>
          <a href="/privacy" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Privacy</a>
        </div>
      </div>
    </div>
  </div>
)
const Privacy = () =>
<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <div  style={{backgroundImage:'radial-gradient(rgba(0,150,0,1), rgba(0,150,0,.4))', marginLeft: '10px', marginRight: '10px', fontWeight: '800', color:'white', width: (isMobile ? width : '50vw'), fontSize: (isMobile ? "20px" : "20px")}}>We at Lethe (Lethe,” “we,” “us,” or “our”), believe your privacy is important.
        To fulfill our commitment to respecting and protecting your privacy, we will adhere to the following principles:<br/>
        1.  We will inform you of the personal information that will be collected, and, where appropriate seek your consent to collect, process, use, or disclose your personal information.<br/>
        2.  Lethe stores ZERO personal information. Not even anonymized data!
    </div>
    <br/>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
      <a href="/" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Home</a>
      <a href="mailto:marc@letheapp.com" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Contact</a>
      <a href="/terms" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Terms</a>
    </div>
  </div>
</div>;

const Terms = () =>
<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <div style={{backgroundImage:'radial-gradient(rgba(0,150,0,1), rgba(0,150,0,.4))', marginLeft: '10px', marginRight: '10px', fontWeight: '800', color:'white', width: (isMobile ? width : '50vw'), fontSize: (isMobile ? "20px" : "20px")}}>
      The information provided on Lethe is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access Lethe from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
    </div>
    <br/>
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
      <a href="/" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Home</a>
      <a href="mailto:marc@letheapp.com" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Contact</a>
      <a href="/privacy" style={{fontWeight: 'bold', color:'black', textDecoration: 'none', fontSize: (isMobile ? "17px" : "25px")}}>Privacy</a>
    </div>
  </div>
</div>

class App extends React.Component {
  constructor(props) {
   super(props);
   this.state = { width: 0, height: 0 };
   this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    width = this.state.width - 10
    height = this.state.height
    console.log("Width ", width)
    console.log("Height ", height)
    console.log("isMobile ", isMobile)
    return (
      <div
      className="col-md-4 col-xs-12"
      style={{
        backgroundImage: `url(${require('./forthepalms.jpeg')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
     >
      <div style={{height:"100vh", width:"100vw", minHeight: "100vh",  minWidth: "100vw"}}>
      <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Landing} />
            <Route path="/support" component={Support} />
            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route component={Landing} />
          </Switch>
      </BrowserRouter>
      </div>
      </div>
    );
  }
}


ReactDOM.render(<App />, document.getElementById("root"));
export default windowSize(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
